<template>
  <div class="store_one_product_block">
    <div class="my_courses_header_block">
      <div class="my_courses_header_container">
        <div class="my_courses_header_label">
          Онлайн магазин
        </div>
        <div class="my_courses_header_basket_block">
<!--          <router-link to="/delivery" class="my_courses_header_delivery">-->
<!--            <div class="my_courses_header_delivery_img">-->
<!--              <div class="my_courses_header_delivery_number">{{ deliveryNumber }}</div>-->
<!--            </div>-->
<!--            <div class="my_courses_header_delivery_text">Доставка</div>-->
<!--          </router-link>-->
          <router-link to="/store/basket" class="my_courses_header_basket">
            <div class="my_courses_header_basket_img">
              <div class="my_courses_header_basket_number">{{ countOfGoods }}</div>
            </div>
            <div class="my_courses_header_basket_text">{{$t('Basket')}}</div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="store_one_product_main_block">
      <div class="store_main_container">
        <div class="store_filter_container">
          <div class="store_coins" v-if="isAuthenticated">
            <div class="store_coins_text">Количество коинов:</div>
            <div class="store_coins_quantity">{{ activeUserCoins }}</div>
          </div>
<!--          <div class="store_filter_label">Сортировка и фильтр</div>-->
<!--          <div class="store_list_sorting">-->
<!--            <div class="store_list_sorting_label">Сортировка</div>-->
<!--            <div class="my_courses_list_radio_block">-->
<!--              <div class="my_courses_radio_item">-->
<!--                <input type="radio" name="sortStore" id="newest" class="my_courses_list_radio"/>-->
<!--                <label for="newest" class="my_courses_radio_label">Популярное</label>-->
<!--              </div>-->
<!--              <div class="my_courses_radio_item">-->
<!--                <input type="radio" name="sortStore" id="popular" class="my_courses_list_radio"/>-->
<!--                <label for="popular" class="my_courses_radio_label">По убыванию цены</label>-->
<!--              </div>-->
<!--              <div class="my_courses_radio_item">-->
<!--                <input type="radio" name="sortStore" id="popular" class="my_courses_list_radio"/>-->
<!--                <label for="popular" class="my_courses_radio_label">По возрастанию цены</label>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="store_catalog">-->
<!--            <div class="store_catalog_label" @click="showList = !showList">Каталог</div>-->
<!--            <div class="store_catalog_search_container" v-show="showList">-->
<!--              <input placeholder="Поиск по товарам"/>-->
<!--            </div>-->
<!--            <div class="store_catalog_list_goods" v-show="showList">-->
<!--              <el-checkbox-group v-model="checkList" class="store_catalog_list_goods">-->
<!--                <el-checkbox class="store_catalog_product" label="electronics">Электроника</el-checkbox>-->
<!--                <el-checkbox class="store_catalog_product" label="accessories">Аксессуары</el-checkbox>-->
<!--                <el-checkbox class="store_catalog_product" label="sport">Спорт</el-checkbox>-->
<!--                <el-checkbox class="store_catalog_product" label="books">Книги</el-checkbox>-->
<!--                <el-checkbox class="store_catalog_product" label="stationery">Канцтовары</el-checkbox>-->
<!--                <el-checkbox class="store_catalog_product" label="clothes">Одежда</el-checkbox>-->
<!--              </el-checkbox-group>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="store_one_product">
          <router-link to="/store" class="store_one_product_arrow_back"/>
          <div class="store_one_product_title">
            {{ this.productItemDesc.translations[0].name }}
          </div>
          <div class="store_one_product_desc">
            <div class="store_one_product_desc_slider">
              <!--              <div class="store_one_product_desc_img"/>-->
              <div class="store_one_product_desc_slider_block">
                <div class="store_one_product_desc_slider_container_arrow_up" @click="showPrevImage"></div>
                <div class="store_one_product_desc_slider_container_arrow_down" @click="showNextImage"></div>
                <div class="store_one_product_desc_slider_container">
                  <img :class="[activeImg(img.image) ? activeClass : '', basicClass]"
                       v-for="img in productItemDesc.gallery"
                       :src="backUrl + img.image"
                       @click="currentImg = img.image"
                       @error="setAltImg"
                  />
                </div>
              </div>
              <div class="store_one_product_desc_img">
                <img :src="backUrl + currentImg"
                     @error="setAltImg"/>
              </div>
            </div>
            <div class="store_one_product_desc_text">
              <div class="store_one_product_desc_price">
                <div class="store_one_product_desc_price_text">{{ $t('Price') }}:</div>
                <div class="store_one_product_desc_price_num">{{ this.productItemDesc.price }}</div>
              </div>
              <div class="store_one_product_desc_available">
                {{ $t('Available_items') }}: {{ this.productItemDesc.count }}
              </div>
              <button v-if="isAuthenticated" class="store_one_product_desc_btn_add" @click="buyGood">{{ $t('Add_to_Basket') }}</button>
              <button v-if="isAuthenticated" class="store_one_product_desc_btn_buy" @click="buyGood(true)">{{ $t('Buy_now') }}</button>
            </div>
          </div>
          <div class="store_one_product_about">
            <div class="store_one_product_about_title">{{ $t('Description') }}</div>
            <div v-show="showDescription" class="store_one_product_about_text">
              <p v-html="this.productItemDesc.translations[0].description"></p>
            </div>
            <div v-show="showDescription" class="store_one_product_about_toggle" @click="showDescription = false">
              Свернуть описание
            </div>
            <div v-show="!showDescription" class="store_one_product_about_toggle" @click="showDescription = true">
              Развернуть описание
            </div>
          </div>
          <div class="store_one_product_characteristic">
            <div class="store_one_product_characteristic_title">
              Общие характеристики
            </div>
            <div v-show="showCharacteristic" class="store_one_product_char_desc">
              <div class="store_one_product_char_item">
                <div class="store_one_product_char_category">Категория</div>
                <div class="store_one_product_char_text">{{productItemDesc.category.translations.find(({locale}) => locale === $i18n.locale).name}}</div>
              </div>
              <div class="store_one_product_char_item" v-for="(value, key) in productItemDesc.property_values" :key="key">
                <div class="store_one_product_char_category">{{value.key.translations.find(({locale}) => locale === $i18n.locale).name}}</div>
                <div class="store_one_product_char_text">{{value.translations.find(({locale}) => locale === $i18n.locale).name}}</div>
              </div>
            </div>
            <div v-show="showCharacteristic" class="store_one_product_char_toggle_two"
                 @click="showCharacteristic = false">Свернуть характеристики
            </div>
            <div v-show="!showCharacteristic" class="store_one_product_char_toggle_one"
                 @click="showCharacteristic = true">Развернуть характеристики
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showModal" class="store_modal">
      <div class="store_modal_back" @click="showModal = false">
      </div>
      <div class="store_modal_front">
        <div class="store_modal_front_title">Товар успешно добавлен в корзину</div>
        <div class="store_modal_front_icon"></div>
        <router-link to="/store/basket" class="store_modal_front_btn">Перейти в корзину</router-link>
        <div class="store_modal_close_form" @click="showModal = false"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "StoreOneProduct",
  data() {
    return {
      basketNumber: 12,
      deliveryNumber: 0,
      showDescription: false,
      showCharacteristic: false,
      showModal: false,
      productItemDesc: [],
      backUrl: window.API_ROOT + '/images/goods/',
      userCoins: null,
      showList: false,
      checkList: [],
      noPhoto: require('@/assets/images/no_photo.png'),
      currentImg: '',
      activeClass: 'store_one_product_desc_slider_active_img',
      basicClass : "store_one_product_desc_slider_basic_img",
      images: [],
      basketData: [],
      isAuthenticated: !!localStorage.getItem('token'),
    }
  },
  methods: {
    handleHover(hovered) {
      this.isHovered = hovered
    },
    addToBasket() {
      this.showModal = true
      this.basketNumber += 1
    },
    buyGood(buyNow=false) {
      let goodId = this.$route.params.id
      if (localStorage.getItem('basket_id')) {
        this.$http.post(API_ROOT + `/api/shop/basket/add`, {
          "basket_id": localStorage.getItem('basket_id'),
          "good_id": goodId
        })
          .then((res) => {
            console.log(res)
            if(!buyNow){
            this.showModal = true}
          })
          .then((res) => {
            if(buyNow){
              this.$router.push('/store/basket')
            }
            else{
              this.quantityGoodsInBasket()
            }

          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.$http.post(API_ROOT + `/api/shop/basket/add`, {"good_id": goodId})
            .then((res) => {
              console.log(res)
              localStorage.setItem('basket_id', res.body.data.id)
              Vue.toastr({
                message: 'Success',
                description: 'Product add to cart',
                type: 'success'
              })
            })
            .then((res) => {
              if(buyNow){
                this.$router.push('/store/basket')
              }
              else{
                this.quantityGoodsInBasket()
              }



            })
            .catch((err) => {
              console.log(err)
            })
      }

    },
    productItem() {
      let item_id = this.$route.params.id
      this.$http.get(API_ROOT + `/api/shop/goods/${item_id}`)
        .then((res) => {
          this.productItemDesc = res.data
          this.currentImg = this.mainImage(this.productItemDesc)
          this.images = this.imagesArr(this.productItemDesc)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setAltImg(event) {
      event.target.src = this.noPhoto
    },
    mainImage(item) {
      for (let i = 0; i < item.gallery.length; i++) {
        if (item.gallery[i].is_main === 1) {
          return item.gallery[i].image
        }
      }
    },
    activeImg(img) {
      return this.currentImg === img
    },
    imagesArr(item) {
      let newArr = []
      for (let i = 0; i < item.gallery.length; i++) {
        newArr.push(item.gallery[i].image)
      }

      return newArr
    },
    showNextImage() {
      let currentIndex = this.images.findIndex(img => img === this.currentImg)
      if (currentIndex === this.images.length - 1) {
        this.currentImg = this.images[0]
      } else {
        this.currentImg = this.images[currentIndex + 1]
      }
    },
    showPrevImage() {
      let currentIndex = this.images.findIndex(img => img === this.currentImg)
      if (currentIndex === 0) {
        this.currentImg = this.images[this.images.length - 1]
      } else {
        this.currentImg = this.images[currentIndex - 1]
      }
    },
    basketList() {

      if (localStorage.getItem('basket_id')) {
        let basket_id = localStorage.getItem('basket_id')

        this.$http.get(API_ROOT + `/api/shop/basket/goods?basket_id=${basket_id} `)
          .then((res) => {
            this.basketData = res.data.data
          })
          .catch(err => {
            console.log(err)
            Vue.toastr({
              message: 'Error',
              description: err.message,
              type: 'error'
            })
          })
      }

    },
    numberGoodsInBasket() {
      if (localStorage.getItem('basket_count')) {
        return this.basketNumber = +localStorage.getItem('basket_count')
      } else {
        return this.basketNumber = 0
      }
    }
  },
  mounted() {
    this.productItem()
    this.basketList()
  },
  computed: {
    activeUserCoins() {
      if (localStorage.getItem('coins')) {
        return this.userCoins = localStorage.getItem('coins')
      } else return this.userCoins = 0
    },
    countOfGoods() {
      let count = 0

      for (let i = 0; i < this.basketData.length; i++) {
        count += this.basketData[i].pivot.count
      }

      return count
    },
  }
}
</script>

<style scoped>

</style>
